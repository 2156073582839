import { Navbar, Nav } from 'react-bootstrap'
import { graphql, StaticQuery, Link } from "gatsby"
import Image from 'gatsby-image'
import styles from './navigation.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import scrollTo from 'gatsby-plugin-smoothscroll';
import React from 'react'


export default () => (
    <StaticQuery
        query={
            graphql`
    query {
        branding: file(relativePath: { regex: "/ANNI_LOGO/" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        allStrapiDates(sort: {order: ASC, fields: datum}, filter: {anzeigen: {eq: true}}) {
            nodes {
              strapiId
            }
          }
    }
    `
        }
        render={(data) => (
            <NavigationComponent data={data}>

            </NavigationComponent>
        )}
    />
)



function handleScroll() {
    const nav_height = 380;
    const cur_pos = window.scrollY;
    const sectionNames = ['home', 'dates', 'press', 'media', 'bio', 'booking'];
    let sections = document.getElementsByTagName('section');


    sectionNames.forEach(sectionName => {
        if (sections.namedItem(sectionName)) {
            const section = sections.namedItem(sectionName)
            const top = section.offsetTop - nav_height;
            const bottom = top + section.offsetHeight;

            if (cur_pos >= top && cur_pos <= bottom) {
                const navItem = document.getElementsByName(sectionName);
                const allaTags = document.getElementsByClassName('nav-link');
                let i;
                for (i = 0; i < allaTags.length; i++) {
                    allaTags.item(i).classList.remove('active');
                }
                navItem.forEach(item => {
                    item.classList.add('active')
                });
            }
        }
    });
}



class NavigationComponent extends React.Component {
    componentDidMount() {
        window.addEventListener('scroll', handleScroll, true);
    };
    render() {
        return (
            <>
                <Navbar className={`${styles.navbar}`} expand="lg" collapseOnSelect={true}>
                    <Navbar.Brand className={styles.navbarBrand} href="/">
                        <Image fluid={this.props.data.branding.childImageSharp.fluid}></Image>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link name="home" href="/" className={`${styles.navLinks} active`} onClick={() => scrollTo('#home')}>Home</Nav.Link>
                            {this.props.data.allStrapiDates.nodes.length > 0 &&
                                <Nav.Link name="dates" className={styles.navLinks} onClick={() => scrollTo('#dates')} href="#">Termine</Nav.Link>
                            }
                            <Nav.Link name="press" className={styles.navLinks} onClick={() => scrollTo('#press')} href="#">Presse</Nav.Link>
                            <Nav.Link name="media" className={styles.navLinks} onClick={() => scrollTo('#media')} href="#">Media</Nav.Link>
                            <Nav.Link name="bio" className={styles.navLinks} onClick={() => scrollTo('#bio')} href="#">Biografie</Nav.Link>
                            <Nav.Link name="booking" className={styles.navLinks} onClick={() => scrollTo('#booking')} href="#">Kontakt</Nav.Link>
                        </Nav>
                        <ul className={`mr-right ${styles.socialItem}`}>
                            <a href="https://de-de.facebook.com/anniperka/" target="_blank"><FontAwesomeIcon icon={fab.faFacebookSquare} size="lg" /></a>
                            <span>&bull;</span>
                            <a href="https://www.instagram.com/anniperka.official/?hl=de" target="_blank"><FontAwesomeIcon icon={fab.faInstagram} size="lg" /></a>
                        </ul>
                    </Navbar.Collapse>

                </Navbar >
                <div className={styles.navHolder}></div>
            </>
        )
    }
}

