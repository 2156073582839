import React from 'react'
import Nav from '../components/navigation'
import Footer from './footer'

const LayoutComponent = props => {
    return (
        <div>
            <Nav />
            {props.children}
            <Footer />
        </div>
    )
}

export default LayoutComponent
