import React from 'react'
import Moment from 'react-moment';
import { Container, Row, Col } from 'react-bootstrap'
import styles from './footer.module.scss'
import {Link} from 'gatsby'

const FooterComponent = () => {
    return (
        <footer className={styles.footer}>
            <Container fluid>
                <Row>
                    <Col lg="12" className="text-center m-0">
                        ©
                        <Moment format="YYYY ">
                            {Date()}
                        </Moment>
                          by Anni Perka
                        <span>&bull;</span>
                        <Link to="/impressum">Impressum</Link>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default FooterComponent
